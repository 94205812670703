import "./App.css";
import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { GlobalContext } from "./hooks/useGlobalContext.ts";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import vi from "./assets/locales/vi.json";
import en from "./assets/locales/en.json";
import LanguageDetector from "i18next-browser-languagedetector";

import { router } from "./routes.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en,
      vi,
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "vi",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const [connection, setConnection] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [user, setUser] = useState();
  const [dialog, setDialog] = useState();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const Component = dialog?.component;
  const id = dialog?.id;
  const innerProps = dialog?.props;

  return (
    <GlobalContext.Provider
      value={{
        language,
        setLanguage,
        connection,
        setConnection,
        user,
        setUser,
        dialog,
        setDialog,
        collapsed,
        setCollapsed
      }}
    >
      {Component && <Component key={id} {...innerProps} />}
      <RouterProvider router={router} />
    </GlobalContext.Provider>
  );
}

export default App;
