import { Space, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../services/userService";
import useEffectOne from "../../hooks/useHook";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import BaseFormik from "../../componentsUltils/Formik/BaseFormik.jsx";
import * as Yup from "yup";
import TextField from "../../componentsUltils/Field/TextField.jsx";
import { useFormik } from "formik";
import { routes } from "../../routes.js";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Required").email(),
  firstName: Yup.string().min(2).required("Required").typeError("Invalid"),
  lastName: Yup.string().min(2).required("Required").typeError("Invalid"),
  username: Yup.string().min(5).required("Required").typeError("Invalid"),
  password: Yup.string().required("Required").min(8).typeError("Invalid"),
});

const SignUp = () => {
  const { connection } = useGlobalContext();
  const navigate = useNavigate();

  useEffectOne(() => {
    if (connection === undefined) return;

    const { state } = connection;

    if (state === "Connected") navigate("/");
  });

  const handleOk = (user) => {
    signUp(user)
      .then(() => {
        navigate("/login");
      })
  };

  const formik = useFormik({
    initialValues: {
      username: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      handleOk(values);
    },
  });

  return (
    <div className="signup-container">
      <BaseFormik formik={formik}>
        <h1>SignUp</h1>
        <Space
          direction="vertical"
        >
          <TextField
            placeholder="First Name"
            id="firstName"
            name="firstName"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Last Name"
            id="lastName"
            name="lastName"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Email"
            id="email"
            name="email"
            prefix={<UserOutlined />}
          />
          <TextField
            placeholder="Username"
            id="username"
            name="username"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Password"
            id="password"
            name="password"
            type="password"
            prefix={<UserOutlined />}
          />
        </Space>
        <div className="signup-bottom">
          <Button onClick={() => navigate(routes.main.login)}>Login</Button>
          <Button type="primary" onClick={formik.handleSubmit} disabled={!formik.isValid}>Save</Button>
        </div>
      </BaseFormik>
    </div>
  );
};

export default SignUp;
