import { useEffect } from "react";
import { useFormik } from "formik";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input, Modal, Space, Typography, Button } from "antd";
import { signIn } from "../../services/userService.js";
import { connect } from "../../services/signalRService.ts";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import BaseFormik from "../../componentsUltils/Formik/BaseFormik.jsx";
import * as Yup from "yup";
import useAuthentication from "../../hooks/useAuthentication.js";
import { routes } from "../../routes.js";
import useCollapse from "../../hooks/useCollapse.js";

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  username: Yup.string().min(5).required("Required").typeError("Invalid"),
  password: Yup.string().required("Required").min(8).typeError("Invalid"),
});

const Login = () => {
  const { openSider } = useCollapse();
  const { setCredential } = useAuthentication();
  const navigate = useNavigate();
  const { setConnection, connection, setUser } = useGlobalContext();

  const handleOk = ({ username, password }) => {
    signIn(username, password)
      .then((res) => {
        const { data } = res;
        connect(data.id)
          .then(({ connection }) => {
            setConnection(connection);
            setUser(data);
            navigate("/");
          })

        setCredential(data);
        openSider();
      })
  };

  useEffect(() => {
    if (connection === undefined) {
      return;
    }
    const { state } = connection;
    if (state === "Connected") navigate("/");
  }, [connection]);

  const onEnterLogin = (e) => {
    if (e.keyCode === 13) formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      handleOk(values);
    },
  });

  return (
    <div className="login-container">
      <BaseFormik formik={formik}>
        <h1>Login</h1>
        <Space direction="vertical">
          <Input
            id="username"
            name="username"
            placeholder="Username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            onKeyDown={onEnterLogin}
            prefix={<UserOutlined />}
          />
          {formik.touched.username && formik.errors.username ? (
            <Text type="danger">{formik.errors.username}</Text>
          ) : null}

          <Input
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onKeyDown={onEnterLogin}
            prefix={<UserOutlined />}
          />
          {formik.touched.password && formik.errors.password ? (
            <Text type="danger">{formik.errors.password}</Text>
          ) : null}
        </Space>
        <div className="login-bottom">
          <Button onClick={() => navigate(routes.main.signUp)}>SignUp</Button>
          <Button type="primary" onClick={formik.handleSubmit} disabled={!formik.isValid}>Login</Button>
        </div>
      </BaseFormik>
    </div>
  );
};

export default Login;
