import { Avatar, Col, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import useAuthentication from "../../hooks/useAuthentication.js";

const UserInfor = () => {
  const { user } = useGlobalContext();
  const { setCredential } = useAuthentication();
  const navigate = useNavigate();
  const { language, setLanguage } = useGlobalContext();

  const handlerSignOut = () => {
    signOut().then(() => {
      // TODO clear credential on globalstate
      setCredential(undefined);
      window.history.go("/");
    });
  };

  const onHandlerChange = () => {
    const lang = language === "vi" ? "en" : "vi";
    setLanguage(lang);
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;
  const firstCharacter = user?.firstName?.split("")[0];
  return (
    <>
      <Col span={24} className="f-mono f-size-larger">
        {user && (
          <>
            <Avatar>{firstCharacter}</Avatar>
            &ensp;
            <span onClick={() => navigate("/")}>{fullName}</span>
            &ensp;
            <LogoutOutlined className="ico-sign-out" onClick={handlerSignOut} />
          </>
        )}
      </Col>
      <br />
      <Col span={24} className="f-mono">
        <Button type="text" className="f-white" onClick={onHandlerChange}>
          En/Vi
        </Button>
      </Col>
    </>
  );
};

export default UserInfor;
