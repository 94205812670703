import { useTranslation } from "react-i18next";
import { Row, Col, Typography, Space } from "antd";
import { Pie, Line, Column } from "@ant-design/plots";
import { countryDic } from "../../../../helpers/countryHelper";
import useEffectOne from "../../../../hooks/useHook";
import { useState } from "react";
import { getUserTicketsByRangeDate } from "../../../../services/userTicketService";

const { Title, Text } = Typography;

const now = new Date();
const firstDayInMonth = new Date(now.getFullYear(), now.getMonth(), 1);
const lastDayInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

const firstDayInYear = new Date(now.getFullYear(), 0, 1);
const lastDayInYear = new Date(now.getFullYear(), 11, 31);

const AnalysisSection = ({ items }) => {
  const [userTicketsInMonth, setUserTicketsInMonth] = useState([]);
  const [userTicketsInYear, setUserTicketsInYear] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalWonCount, setTotalWonCount] = useState(0);
  const [wonAmount, setWonAmount] = useState(0);
  const { t } = useTranslation();
  const alreadyTicketsInday = items !== undefined && items.length > 0;

  useEffectOne(() => {
    const getChartInMonth = async () => {
      const res = await getUserTicketsByRangeDate(firstDayInMonth, lastDayInMonth)
      const {
        data: { data },
      } = res;
      const totalCount = data.reduce((value, pre) => value + pre.quantity, 0);
      const totalWonTickets = data.filter((s) => s.ticketResult?.isWon);
      const totalWonCount = totalWonTickets.reduce(
        (value, pre) => value + pre.quantity,
        0
      );
      const wonAmount = totalWonTickets.reduce(
        (amount, pre) => amount + pre.ticketResult?.amount,
        0
      );
      setTotalCount(totalCount);
      setTotalWonCount(totalWonCount);
      setWonAmount(wonAmount);
      setUserTicketsInMonth(data);
    }

    const getChartInYear = async () => {
      const res = await getUserTicketsByRangeDate(firstDayInYear, lastDayInYear);
      const { data: { data } } = res;
      setUserTicketsInYear(data);
    }

    Promise.all([getChartInMonth(), getChartInYear()]);
  });



  return (
    <>
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>{t("dashboard_page.analysis")}</Title>
        </Col>
      </Row>
      {alreadyTicketsInday && (
        <Row className="bg-white">
          <Col className="p-l-20 m-t-20" span={24}>
            <Title level={4}>
              {t("dashboard_page.sections.analysis.inDay")}
            </Title>
          </Col>
          <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
            <TotalPieChart items={items} />
          </Col>
        </Row>
      )}
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>
            {t("dashboard_page.sections.analysis.inMonth", {
              val: now,
              formatParams: {
                val: {
                  month: "long",
                },
              },
            })}
          </Title>
        </Col>
        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          <Space direction="vertical">
            <Text strong>
              {t("dashboard_page.sections.analysis.totalCount", {
                val: totalCount,
              })}
            </Text>
            <Text strong>
              {t("dashboard_page.sections.analysis.totalWonCount", {
                val: totalWonCount,
              })}
            </Text>
            <Text strong>
              {t("dashboard_page.sections.analysis.wonAmount", {
                val: wonAmount,
              })}
            </Text>
          </Space>
          <RatePieChart items={userTicketsInMonth} />
        </Col>
        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          {/* TODO in month */}
          <InMonthLineChart items={userTicketsInMonth} />
        </Col>
      </Row>
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>
            {t("dashboard_page.sections.analysis.inYear", {
              val: now,
              formatParams: {
                val: {
                  year: 'numeric'
                },
              },
            })}
          </Title>
        </Col>
        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          {userTicketsInYear && <InYearPieChart items={userTicketsInYear} />}
        </Col>

        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          {userTicketsInYear && <InYearColumnChart items={userTicketsInYear} />}
        </Col>
      </Row>
    </>
  );
};

const RatePieChart = ({ items }) => {
  const { t } = useTranslation();
  if (items === undefined || items.length === 0) return <>Empty data</>;

  const totalWonTickets = items
    .filter((s) => s.ticketResult?.isWon)
    .reduce((value, p) => value + p.quantity, 0);
  const totalNoCheckTickets = items
    .filter((s) => s.ticketResult === null)
    .reduce((value, p) => value + p.quantity, 0);

  const totalLoseTickets = items
    .filter((s) => s.ticketResult !== null)
    .filter((s) => !s.ticketResult.isWon)
    .reduce((value, p) => value + p.quantity, 0);

  const config = {
    data: [
      { type: t("dashboard_page.sections.analysis.wonIndicator"), value: totalWonTickets },
      { type: t("dashboard_page.sections.analysis.loseIndicator"), value: totalLoseTickets },
      { type: t("dashboard_page.sections.analysis.noCheckIndicator"), value: totalNoCheckTickets },
    ],
    angleField: "value",
    colorField: "type",
    label: {
      text: (s) => `${s.value} ${t("count")}`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };

  return <Pie {...config} />;
};

const TotalPieChart = ({ items }) => {
  const { t } = useTranslation();
  if (items === undefined || items.legend === 0)
    return <Text>Empty data for chart</Text>;

  const models = items.reduce(
    (totals, p) => ({
      ...totals,
      [p.country]: (totals[p.country] || 0) + p.quantity,
    }),
    {}
  );
  const data = [];
  for (const k in models) {
    const itemCount = models[k];
    const value = { type: countryDic[k].name, value: itemCount };
    data.push(value);
  }

  const config = {
    data,
    angleField: "value",
    colorField: "type",
    label: {
      text: (s) => `${s.value} ${t("count")}`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };
  return <Pie {...config} />;
};

const InYearPieChart = ({ items }) => {
  const countries = new Map();
  for (const item of items) {
    const lotteriesCount = countries.get(item.country);
    if (lotteriesCount === undefined) {
      countries.set(item.country, item.quantity);
      continue;
    }
    countries.set(item.country, lotteriesCount + item.quantity);
  }

  const totalCount = items.reduce((pre, current) => pre + current.quantity, 0)
  if (totalCount === 0) return;

  const formatCountries = [];
  countries.forEach((v, k) => formatCountries.push({
    type: countryDic[k].name,
    value: v,
    key: k
  }))

  let result = [];
  let prevBaseLine = 0;
  let cummulValue = 0;
  let i = 0
  for (let item of formatCountries) {
    const percentage = (item.value / totalCount) * 100;
    if (cummulValue === 0) {
      const cumulRounded = Math.round(percentage);
      result[i] = { type: item.type, value: cumulRounded }
      cummulValue += percentage;
      prevBaseLine = cumulRounded;
      i++
      continue;
    }
    cummulValue += percentage;
    const cumulRounded = Math.round(cummulValue);
    result[i] = { type: item.type, value: cumulRounded - prevBaseLine }
    prevBaseLine = cumulRounded;
    i++
  }

  const config = {
    data: result,
    angleField: "value",
    colorField: "type",
    label: {
      text: (s) => `${s.value}%`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };
  return <Pie {...config} />
}

const InMonthLineChart = ({ items }) => {
  const models = items.reduce(
    (totals, pre) => ({
      ...totals,
      [new Date(pre.date).getDate()]:
        (totals[new Date(pre.date).getDate()] || 0) + pre.quantity,
    }),
    {}
  );
  const data = [];
  let i = firstDayInMonth.getDate();
  const dayLength = lastDayInMonth.getDate();
  for (i; i <= dayLength; i++) {
    let itemDefault = { Day: i, Count: 0 };
    const itemCount = models[i];
    if (itemCount === undefined) {
      data.push(itemDefault);
      continue;
    }
    itemDefault.Count = itemCount;
    data.push(itemDefault);
  }

  const config = {
    data,
    xField: "Day",
    yField: 'Count',
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
    text: "Vé",
  };
  return <Line {...config} />;
};


const InYearColumnChart = ({ items }) => {
  const { t } = useTranslation();
  if (items === undefined || items.legend === 0)
    return <Text>Empty data for chart</Text>;

  const models = items.reduce(
    (totals, p) => ({
      ...totals,
      [p.country]: (totals[p.country] || 0) + p.quantity,
    }),
    {}
  );

  const data = [];
  for (const k in models) {
    const itemCount = models[k];
    const value = { type: countryDic[k].name, [t("count")]: itemCount };
    data.push(value);
  }

  const config = {
    data,
    xField: 'type',
    yField: t("count"),
    label: {
      text: (d) => `${d[t('count')]}`,
      textBaseline: 'bottom',
    },
    style: {
      // 圆角样式
      radiusTopLeft: 10,
      radiusTopRight: 10,
    },
  };
  return <Column {...config} />
}

export default AnalysisSection;
