
export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


const counterDuplicate = (txt) => {
  const words = txt.split(' ');
  const countWords = words.length;
  const countChars = words.filter(s => s.length >= 2).reduce((a, b) => a + b.length, 0);
  const ave = Math.round(countChars / countWords);
  return ave;
}