import { Layout, Alert } from "antd";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ChatGroup from "../components/chat-group/chat-group";
import useAlert from "../hooks/useAlert";
import { useMemo } from "react";
import axios from "axios";
import { getCurrentUser } from "../services/userService";
import { connect } from "../services/signalRService.ts";
import { isEmpty } from "lodash";
import { useGlobalContext } from "../hooks/useGlobalContext.ts";
import useEffectOnce from "../hooks/useHook";
import MainSkelethon from "../components/skelethon";
import useAuthentication from "../hooks/useAuthentication.js";
import useCollapse from "../hooks/useCollapse.js";

const { Sider } = Layout;

function LayoutInner() {
  const { collapsed, toggleSider } = useCollapse();
  const { alert, callAlert } = useAlert();
  const { isAuthenticate, setCredential } = useAuthentication();
  const navigate = useNavigate();
  const { setConnection, setUser, user } = useGlobalContext();

  useEffectOnce(() => {
    if (!isAuthenticate) {
      navigate("/login");
      return;
    }

    getCurrentUser().then((res) => {
      const user = res.data;
      if (isEmpty(user)) return;

      connect(user.id)
        .then(({ connection }) => {
          setConnection(connection);
          setUser(user);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  });

  useMemo(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        handlerStatusCode(error);
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line
  }, [callAlert]);

  const clearCredential = () => {
    setConnection(undefined);
    setUser(undefined);
    setCredential(undefined);
  };

  const handlerStatusCode = (error) => {
    const {
      message,
      response: { status, data },
    } = error;
    switch (status) {
      case 401:
        clearCredential();
        navigate("/login");
        return;
      case 403:
        navigate("/");
        break;
      default:
        break;
    }

    const mess = isEmpty(data)
      ? message
      : isEmpty(data.message)
        ? message
        : data.message;
    callAlert().error(mess);
  };

  return (
    <Layout>
      {alert !== undefined && (
        <Alert
          className="alert"
          message={alert.message}
          type={alert.type}
          showIcon
        />
      )}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="main-color min-height"
        width={300}
        collapsed={collapsed}
        onCollapse={() => toggleSider()}
      >
        <div className={!collapsed && "sider-fixed"}>
          {user ? <ChatGroup /> : <MainSkelethon />}
        </div>
      </Sider>
      <Layout>
        <Outlet />
      </Layout>
    </Layout>
  );
}

export default LayoutInner;
