import { useGlobalContext } from "./useGlobalContext.ts";

const useCollapse = () => {
    const { collapsed, setCollapsed } = useGlobalContext();

    const openSider = () => {
        setCollapsed(false);
    }

    const closeSider = () => {
        setCollapsed(true);
    }

    const toggleSider = () => {
        setCollapsed(!collapsed);
    }

    return { openSider, closeSider, toggleSider, collapsed }
}

export default useCollapse;